<template>
  <znl-dialog
      :visible.sync="dialog.visible"
      :title="dialog.title"
      width="600"
      :close-on-click-modal="false"
      height="auto">
    <el-form>
      <el-form-item label="用户名" :required="true">
        <znl-input
            type="text"
            :border="true"
            v-model="editItem.Account"
            placeholder="用户名"></znl-input>
      </el-form-item>
      <el-form-item label="姓名" :required="true">
        <znl-input
            type="text"
            :border="true"
            v-model="editItem.UserName"
            placeholder="姓名"></znl-input>
      </el-form-item>
      <el-form-item label="密码" :required="!isEditMode">
        <znl-input
            type="password"
            :border="true"
            v-model="editItem.Password"
            placeholder="密码"></znl-input>
      </el-form-item>
      <el-form-item label="手机">
        <znl-input
            type="text"
            :border="true"
            v-model="editItem.Mobile"
            placeholder="手机"></znl-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <znl-input
            type="text"
            :border="true"
            v-model="editItem.Email"
            placeholder="邮箱"></znl-input>
      </el-form-item>
      <el-form-item label="备注">
        <znl-input
            type="text"
            :border="true"
            v-model="editItem.Remark"
            placeholder="备注"></znl-input>
      </el-form-item>
      <el-form-item label="请选择角色" :required="true">
        <el-checkbox v-for="item in userRoles" :key="item.RoleId" v-model="item.HasRole">
          {{item.RoleName}}
        </el-checkbox>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <znl-button :disabled="isSaveShow" :height="30" :width="90" @click="onSave" style-type="main">提交</znl-button>
      <znl-button :height="30" :width="90" @click="onCancel">取消</znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import { GetUserRole, SaveUser } from "@/api/user";
const CONFIG = {
  urls: {
    GetList: 'User/GetList',
    Add: 'User/Add',
    Update: 'User/Update',
    GetUserRole: 'User/GetUserRole'
  }
}

export default {
  name: 'UserEdit',

  data () {
    return {
      isEditMode: false,
      editItem: {
        Id: 0,
        UserName: '',
        Account: '',
        Password: '',
        Mobile: '',
        Email: '',
        Remark: '',
      },
      dialog: {
        title: '',
        visible: false
      },
      userRoles: [],
      isSaveShow: false
    }
  },

  methods: {
    open (item) {
      this.isEditMode = item != undefined
      if (this.isEditMode) {
        this.dialog.title = '编辑用户'
        this.setEditItem(item)
      } else {
        this.dialog.title = '新增用户'
        this.editItem = {
          Id: 0,
          UserName: '',
          Account: '',
          Password: '',
          Mobile: '',
          Email: '',
          Remark: '',
        }
      }
      this.getUserRole(this.editItem.Id)

      this.dialog.visible = true
    },

    setEditItem (item) {
      const {editItem} = this
      Object.keys(item)
          .filter(key => editItem.hasOwnProperty(key))
          .forEach(key => editItem[key] = item[key])
    },

    async onSave () {
      const {isEditMode, editItem} = this
      if (isEditMode) {
        await this.saveEditItem(editItem)
      } else {
        const data = await this.saveNewItem(editItem)
        editItem.Id = data.Id
      }

      this.$message({message: '用户已保存', type: 'success'})
      this.close(editItem)
    },

    async saveNewItem (item) {
      const sendData = {}
      const excludeKeys = ['Id']
      Object.keys(item)
          .filter(key => !key.startsWith('_') && !excludeKeys.includes(key))
          .forEach(key => sendData[key] = item[key])

      this.isSaveShow = true

      try {
        const data = await SaveUser(sendData)
        return data
      } finally {
        this.isSaveShow = false
      }

    },

    async saveEditItem (item) {
      const sendData = {}
      const excludeKeys = []
      Object.keys(item)
          .filter(key => !key.startsWith('_') && !excludeKeys.includes(key))
          .forEach(key => sendData[key] = item[key])

      sendData.UserRoles = this.userRoles

      this.isSaveShow = true
      try {
        await SaveUser(sendData)
      } finally {
        this.isSaveShow = false
      }

    },

    onCancel () {
      this.close(null)
    },

    close (item) {
      this.dialog.visible = false
      this.$emit('close', item)
    },

    async getUserRole () {
      let data = await GetUserRole({Id: this.editItem.Id})
      this.userRoles = data
    }
  },

  created () {

  },
}
</script>
