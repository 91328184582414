<template>
  <div class="m-grid p-page-user">
      <section class="p-list-search">
        <div class="p-input-search">
          <znl-input v-model="listQuery.Account" border clearable placeholder="用户名" width="130px"></znl-input>
          <znl-input v-model="listQuery.UserName" border clearable placeholder="姓名" width="130px"></znl-input>
          <el-button class="m-grid-btn" type="primary" @click="onSearch()">查询</el-button>
          <el-button class="m-grid-btn" type="primary" @click="onOpenEdit">新增用户</el-button>
        </div>
      </section>

      <section class="m-grid-list">
        <grid
            ref="list"
            :columns="columns"
            :datas="itemSource"
            :onPageChanged="onSearch"
            :page-index="pagination.page"
            :page-size="pagination.size"
            :selection="false"
            :total-count="pagination.total"
            headerHeight="35"
            type="base"
        >
        </grid>
      </section>
    <user-edit v-if="isEditMode" @close="onCloseEdit" ref="edit" />
  </div>
</template>

<script>
const columns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
    valueFormatter: ({ node: { rowIndex } }) => {
      return rowIndex + 1;
    }
  },
  {
    field: 'Account',
    headerName: '用户名',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UserName',
    headerName: '姓名',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'SignInTimes',
    headerName: '登录次数',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'LastSignTime',
    headerName: '最后登录时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'IsMainAccount',
    headerName: '主账号',
    editable: false,
    //dataMap: commonRuleClientOptions,
    cellClass: 't-c',
    valueFormatter({ value }) {
      return value ? '主账户': ''
    },
  },
  {
    field: 'Mobile',
    headerName: '手机号',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'Email',
    headerName: '邮箱',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'Remark',
    headerName: '备注',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
import { GetUserList, LockUser } from "@/api/user";
import UserEdit from './components/user-edit'
export default {
  components: {
    UserEdit
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      listQuery: {
        Account: '',
        UserName: ''
      },
      isEditMode: false,
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      }
    };
  },
  methods: {
    async onSearch(page = 1) {
      const { pagination } = this;
      const sendData = {
        ...this.listQuery,
        Page: page,
        Limit: pagination.size,
      };
      const data = await GetUserList(sendData); // 请求接口
      pagination.page = data.Page;
      pagination.total = data.Total;
      this.itemSource = data.Items;
    },
    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OptsColumn = columns.find((item) => item.field === "operation");
      if (OptsColumn) {
        OptsColumn.cellRendererParams = {
          buttons: function (param) {
            const { data: { IsActive, IsMainAccount } } = param;
            const btns = []
            const activeBtn = {
              text: IsActive == 2 ? '停用': '启用',
                  click(params) {
                    if (IsMainAccount) return
                    self.onSetEnabled(params.data);
                  }
            };
            btns.push({
              text: "编辑",
              click(params) {
                self.onOpenEdit(params.data);
              },
            });
            (!IsMainAccount) && btns.push(activeBtn)
            return btns
          }
        };
      }
      this.$refs.list.changeColumns(columns);
    },
    onSetEnabled(row) {
      const { UserID, IsActive } = row
      const tips = IsActive == 2 ? '停用': '启用'
      this.$confirm(`您确定${ tips }该用户?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = { Id: UserID, IsLocked: IsActive == 2 }
        LockUser(param).then(res=> {
          this.onSearch()
          this.$message({
            type: 'success',
            message: `${tips}成功!`
          });
        })
      }).catch(() => {
      });
    },
    async onOpenEdit(item) {
      this.isEditMode = true
      await this.$nextTick()
      this.$refs.edit.open(item)
    },
    onCloseEdit(item) {
      this.isEditMode = false
      if (item) {
        this.onSearch(this.pagination.page)
      }
    }
  },
  created() {
    this.onSearch();
  },
  mounted() {
    this.initColumns();
  },
};
</script>

<style lang="scss">
.p-page-user {
  .p-input-search, .m-grid-search {
    margin-bottom: 10px;
  }
  .p-input-search > * {
    margin-right: 10px;
  }
}
</style>
